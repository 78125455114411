import React from 'react';
import { Card, Tabs, Tab } from 'react-bootstrap';
import './Fundamentals.css';

const FundamentalsDocumentation = () => {
    return (
        <div className="fundamentals-documentation">
            <h1>Fundamentals API Documentation</h1>

            {/* Overview Card */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Interfacing with Financial Data Routes</Card.Title>
                    <p>Our API provides access to a wide range of financial data endpoints, including Income Statements, Balance Sheets, and specific financial metrics. The API supports filtering by date range for both point-in-time (instantaneous) and period-based data.</p>
                    <p><strong>Note:</strong> You can specify <code>start_date</code> and <code>end_date</code> to filter data across all endpoints. However, the response structure will differ:</p>
                    <ul>
                        <li><strong>Instantaneous Data</strong> endpoints return a single date (<code>end_date</code>) in the response, even if a date range is provided.</li>
                        <li><strong>Period-Based Data</strong> endpoints include both <code>start_date</code> and <code>end_date</code> in the response, representing the reporting period.</li>
                    </ul>
                </Card.Body>
            </Card>

            {/* Endpoints Card */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Endpoints</Card.Title>
                    <h3>Instantaneous Data</h3>
                    <p>These endpoints return point-in-time values, and while you can filter by <code>start_date</code> and <code>end_date</code>, only <strong>end_date</strong> will be included in the response as the date representing the data.</p>
                    <ul>
                        <li><code>/financials/assets</code></li>
                        <li><code>/financials/current_assets</code></li>
                        <li><code>/financials/current_liabilities</code></li>
                        <li><code>/financials/inventory</code></li>
                        <li><code>/financials/liabilities</code></li>
                        <li><code>/financials/property_plant_and_equipment</code></li>
                        <li><code>/financials/shares</code></li>
                        <li><code>/financials/total_stockholders_equity</code></li>
                    </ul>

                    <h3>Period-Based Data</h3>
                    <p>These endpoints provide data over a specified period. The response will include both <strong>start_date</strong> and <strong>end_date</strong> fields, representing the reporting range.</p>
                    <ul>
                        <li><code>/financials/cash_financing_activities</code></li>
                        <li><code>/financials/cash_investing_activities</code></li>
                        <li><code>/financials/cash_operating_activities</code></li>
                        <li><code>/financials/balance_sheet</code></li>
                        <li><code>/financials/income</code></li>
                        <li><code>/financials/net_income_loss</code></li>
                        <li><code>/financials/operating_expenses</code></li>
                        <li><code>/financials/revenue</code></li>
                    </ul>
                </Card.Body>
            </Card>

            {/* Query Parameters Card */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Query Parameters</Card.Title>
                    <p>Each endpoint allows you to specify query parameters to filter the data:</p>
                    <ul>
                        <li><strong>symbol</strong> (required): Stock symbol (e.g., "AAPL")</li>
                        <li><strong>start_date</strong> (optional): Start date for filtering results in YYYY-MM-DD format</li>
                        <li><strong>end_date</strong> (optional): End date for filtering results in YYYY-MM-DD format</li>
                        <li><strong>latest_n_records</strong> (optional): Fetch the latest N records</li>
                    </ul>
                    <p><strong>Note:</strong> The API will return data within the specified date range regardless of whether the endpoint is instantaneous or period-based. The difference is only in the response format:</p>
                    <ul>
                        <li>For <strong>instantaneous data</strong>, only <code>end_date</code> will be present in the response.</li>
                        <li>For <strong>period-based data</strong>, both <code>start_date</code> and <code>end_date</code> will be included in the response.</li>
                    </ul>
                </Card.Body>
            </Card>

            {/* Authentication Card */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Authentication</Card.Title>
                    <p>To access our API, include your API key in the headers using the <code>X-API-KEY</code> header.</p>
                    <p><strong>Why <code>X-API-KEY</code>?</strong> This header provides a standard way to authenticate without mixing authentication details with other request parameters, enhancing security.</p>
                </Card.Body>
            </Card>

            {/* Example API Requests */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Example API Requests</Card.Title>
                    <Tabs defaultActiveKey="curl" id="api-examples-tabs">
                        {/* cURL Examples */}
                        <Tab eventKey="curl" title="cURL">
                            <h4>Fetch Instantaneous Data (e.g., Assets)</h4>
                            <pre>{`curl -X GET "https://api.equityexplorer.io/financials/assets?symbol=AAPL&start_date=2022-01-01&end_date=2022-12-31" \\
-H "X-API-KEY: YOUR_API_KEY"`}</pre>

                            <h4>Fetch Period-Based Data (e.g., Cash Financing Activities)</h4>
                            <pre>{`curl -X GET "https://api.equityexplorer.io/financials/cash_financing_activities?symbol=AAPL&start_date=2022-01-01&end_date=2022-12-31" \\
-H "X-API-KEY: YOUR_API_KEY"`}</pre>
                        </Tab>

                        {/* Python Examples */}
                        <Tab eventKey="python" title="Python">
                            <h4>Fetch Instantaneous Data (e.g., Assets)</h4>
                            <pre>{`import requests

url = 'https://api.equityexplorer.io/financials/assets'
headers = {'X-API-KEY': 'YOUR_API_KEY'}
params = {'symbol': 'AAPL', 'start_date': '2022-01-01', 'end_date': '2022-12-31'}

response = requests.get(url, headers=headers, params=params)
print(response.json())`}</pre>

                            <h4>Fetch Period-Based Data (e.g., Cash Financing Activities)</h4>
                            <pre>{`import requests

url = 'https://api.equityexplorer.io/financials/cash_financing_activities'
headers = {'X-API-KEY': 'YOUR_API_KEY'}
params = {'symbol': 'AAPL', 'start_date': '2022-01-01', 'end_date': '2022-12-31'}

response = requests.get(url, headers=headers, params=params)
print(response.json())`}</pre>
                        </Tab>

                        {/* JavaScript Examples */}
                        <Tab eventKey="javascript" title="JavaScript">
                            <h4>Fetch Instantaneous Data (e.g., Assets)</h4>
                            <pre>{`const axios = require('axios');

const url = 'https://api.equityexplorer.io/financials/assets';
const headers = { 'X-API-KEY': 'YOUR_API_KEY' };
const params = { symbol: 'AAPL', start_date: '2022-01-01', end_date: '2022-12-31' };

axios.get(url, { headers, params })
    .then(response => console.log(response.data))
    .catch(error => console.error(error));`}</pre>

                            <h4>Fetch Period-Based Data (e.g., Cash Financing Activities)</h4>
                            <pre>{`const axios = require('axios');

const url = 'https://api.equityexplorer.io/financials/cash_financing_activities';
const headers = { 'X-API-KEY': 'YOUR_API_KEY' };
const params = { symbol: 'AAPL', start_date: '2022-01-01', end_date: '2022-12-31' };

axios.get(url, { headers, params })
    .then(response => console.log(response.data))
    .catch(error => console.error(error));`}</pre>
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>

            {/* API Response Documentation */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>API Response Format</Card.Title>
                    <h3>Instantaneous Data Response</h3>
                    <pre>{`{
    "symbol": "AAPL",
    "end_date": "2023-12-31",
    "value": 1234567.89
}`}</pre>
                    <p>This response includes the <code>symbol</code>, <code>end_date</code> (representing the single point-in-time data), and <code>value</code> representing the financial metric.</p>

                    <h3>Period-Based Data Response</h3>
                    <pre>{`{
    "symbol": "AAPL",
    "start_date": "2022-01-01",
    "end_date": "2022-12-31",
    "value": 5678901.23
}`}</pre>
                    <p>This response includes the <code>symbol</code>, <code>start_date</code> and <code>end_date</code> representing the reporting period, and <code>value</code> for the specified range.</p>
                </Card.Body>
            </Card>

            {/* Further Resources */}
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Resources for Further Learning</Card.Title>
                    <ul>
                        <li><a href="https://restfulapi.net/" target="_blank" rel="noopener noreferrer">REST API Tutorial</a></li>
                        <li><a href="https://docs.github.com/en/rest" target="_blank" rel="noopener noreferrer">Introduction to REST APIs on GitHub</a></li>
                    </ul>
                </Card.Body>
            </Card>
        </div>
    );
};

export default FundamentalsDocumentation;
