import React from 'react';
import Card from '../Cards/Card';
import './About.css';

const About = () => {
    return (
        <div className="container my-5">
            <div className="row">
                <div className="col-md-4 mb-4">
                    <Card
                        title="About Equity Explorer"
                        content={
                            <p>
                                Welcome to Equity Explorer, your trusted source for accurate and affordable stock data directly extracted from SEC quarterly reports.
                                We aim to make high-quality financial data accessible to everyone by providing a comprehensive, user-friendly platform designed for developers,
                                analysts, and finance enthusiasts.
                            </p>
                        }
                    />
                </div>

                <div className="col-md-4 mb-4">
                    <Card
                        title="What We Do"
                        content={
                            <ul>
                                <li><strong>Assets</strong> - Track information related to assets such as current assets, intangible assets, and property, plant, and equipment.</li>
                                <li><strong>Cash Financing Activities</strong> - Monitor the financing cash flows, including loans and debt repayment.</li>
                                <li><strong>Cash Investing Activities</strong> - Track cash used or provided by investments, such as acquisitions and capital expenditures.</li>
                                <li><strong>Cash Operating Activities</strong> - Keep track of the operational cash flow, like receipts from customers and payments to suppliers.</li>
                                <li><strong>Common Stock</strong> - Monitor changes in common stock including stock issuance and buybacks.</li>
                                <li><strong>Comprehensive Income</strong> - View the company's total income, including all revenues and expenses.</li>
                                <li><strong>Cost of Revenue</strong> - Understand the direct costs incurred to produce revenue.</li>
                                <li><strong>Depreciation and Amortization</strong> - Track the systematic allocation of the cost of tangible and intangible assets over time.</li>
                                <li><strong>Earnings Per Share (EPS)</strong> - Analyze both basic and diluted EPS for insights into profitability.</li>
                                <li><strong>Net Income Loss</strong> - Understand the company’s net earnings or losses over a given period.</li>
                                <li><strong>Revenue</strong> - Track total revenue generated by the company from its core business activities.</li>
                                <li><strong>Liabilities</strong> - Review current and non-current liabilities like debts, accounts payable, and other obligations.</li>
                            </ul>
                        }
                    />
                </div>


                <div className="col-md-4 mb-4">
                    <Card
                        title="Platform Features"
                        content={
                            <ul>
                                <li><strong>Financial Data Browsing</strong> - Navigate through our extensive catalog of financial data with ease directly on our platform.</li>
                                <li><strong>RESTful API Access</strong> - Programmatically access data via a robust, well-documented API for easy integration into your applications.</li>
                                <li><strong>Data Validation</strong> - Each data point is validated for accuracy, ensuring reliable insights.</li>
                                <li><strong>Secure Access</strong> - User authentication through API keys ensures secure and personalized access to the data.</li>
                            </ul>
                        }
                    />
                </div>

                <div className="col-md-4 mb-4">
                    <Card
                        title="Why Choose Us?"
                        content={
                            <p>
                                We focus on affordability and accessibility, providing developers and financial professionals with reliable stock data without breaking the bank.
                                Our platform is built with transparency and data accuracy in mind, ensuring that you can trust the information we deliver.
                            </p>
                        }
                    />
                </div>

                <div className="col-md-4 mb-4">
                    <Card
                        title="API Basics"
                        content={
                            <>
                                <p>Our RESTful API allows for easy access to financial data, letting you integrate it into your own applications seamlessly.</p>
                                <ul>
                                    <li><strong>Authentication</strong>: Use an API key to access endpoints securely.</li>
                                    <li><strong>GET Requests</strong>: Access balance sheets, income statements, and cash flows directly through GET requests.</li>
                                    <li><strong>JSON Format</strong>: All responses are provided in JSON format, making it easy to work with the data.</li>
                                    <li><strong>Pagination & Limits</strong>: To prevent overwhelming the server, requests may be paginated or limited in volume.</li>
                                </ul>
                            </>
                        }
                    />
                </div>

                <div className="col-md-4 mb-4">
                    <Card
                        title="Future Plans"
                        content={
                            <>
                                <ul>
                                    <li><strong>End of Day Stock Prices</strong> - Up-to-date price data.</li>
                                    <li><strong>ETF Holdings & Analytics</strong> - Enhanced analysis tools for Exchange Traded Funds (ETFs).</li>
                                    <li><strong>Data Monetization</strong> - We may introduce subscription tiers in the future to support the platform and fund new features.</li>
                                </ul>
                                <p>Our commitment is to continuously improve and expand our offerings based on user feedback and industry demands.</p>
                            </>
                        }
                    />
                </div>

                <div className="col-md-4 mb-4">
                    <Card
                        title="Frequently Asked Questions"
                        content={
                            <>
                                <h4>Where does the data come from?</h4>
                                <p>Our data is sourced directly from the SEC’s public reports, ensuring transparency and reliability.</p>

                                <h4>How is the data validated?</h4>
                                <p>Each data point undergoes rigorous validation checks upon entry, minimizing errors and ensuring accuracy.</p>

                                <h4>What financial statements are available?</h4>
                                <p>We provide access to balance sheets, income statements, and cash flow statements, extracted from quarterly SEC reports.</p>

                                <h4>Can I access the data programmatically?</h4>
                                <p>Yes! Use our RESTful API to retrieve data programmatically. Documentation and examples are available to help you get started.</p>

                                <h4>Is the data adjusted for splits or dividends?</h4>
                                <p>At this time, data is provided as-is from SEC reports. Adjustments for splits and dividends may be introduced in future updates.</p>

                                <h4>What support options are available?</h4>
                                <p>We provide a knowledge base, extensive documentation, and email support for further assistance.</p>
                            </>
                        }
                    />
                </div>

                <div className="col-md-4 mb-4">
                    <Card
                        title="Best Practices for API Usage"
                        content={
                            <>
                                <ul>
                                    <li><strong>Limit Requests</strong> - Avoid excessive requests in a short period to prevent hitting rate limits.</li>
                                    <li><strong>Handle Errors Gracefully</strong> - Implement error handling to manage scenarios like rate limits, invalid requests, or network issues.</li>
                                    <li><strong>Use Pagination</strong> - If your request returns large datasets, use pagination to manage data flow.</li>
                                    <li><strong>Secure Your API Key</strong> - Keep your API key confidential to avoid unauthorized access.</li>
                                </ul>
                            </>
                        }
                    />
                </div>

                <div className="col-md-4 mb-4">
                    <Card
                        title="Legal Considerations"
                        content={
                            <p>
                                Our data is derived from publicly available information provided by the SEC. Redistribution of this data for commercial use without permission is prohibited.
                                We strive to provide transparent and ethical access to financial information for all users.
                            </p>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default About;
