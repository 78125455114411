import React, { useState, useEffect } from 'react';
import axiosInstance from '../Requests/Axios';
import './SearchBar.css';

interface SymbolSearchBarProps {
    onSelect: (symbols: string[]) => void; // Expect an array of symbols
}

const SymbolSearchBar: React.FC<SymbolSearchBarProps> = ({ onSelect }) => {
    const [query, setQuery] = useState("");
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const [symbols, setSymbols] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [selectedSymbols, setSelectedSymbols] = useState<string[]>([]); // Track selected symbols

    useEffect(() => {
        const fetchSymbols = async () => {
            try {
                const response = await axiosInstance.get('/stocks/symbols');
                setSymbols(response.data);
            } catch (err: any) {
                setError('Failed to fetch symbols. Please try again later.');
                console.error(err);
            }
        };

        fetchSymbols();
    }, []);

    useEffect(() => {
        if (query) {
            const filteredSuggestions = symbols
                .filter(symbol => symbol.toLowerCase().startsWith(query.toLowerCase()))
                .slice(0, 5);
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    }, [query, symbols]);

    const handleSelect = (symbol: string) => {
        // Add the selected symbol if not already in the list
        if (!selectedSymbols.includes(symbol)) {
            const updatedSymbols = [...selectedSymbols, symbol];
            setSelectedSymbols(updatedSymbols);
            onSelect(updatedSymbols); // Pass the updated list of symbols to the parent
        }
        setQuery(''); // Clear the input after selection
        setSuggestions([]);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    return (
        <div className="search-bar-container">
            <input
                type="text"
                value={query}
                onChange={handleInputChange}
                placeholder="Search for stock symbols"
                className="search-input"
            />
            {error && <p className="error">{error}</p>}
            {suggestions.length > 0 && (
                <ul className="suggestions-list">
                    {suggestions.map((symbol) => (
                        <li
                            key={symbol}
                            onClick={() => handleSelect(symbol)}
                            className="suggestion-item"
                        >
                            {symbol}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SymbolSearchBar;
