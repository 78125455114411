import React from 'react';
import Table from 'react-bootstrap/Table';

interface DataTableProps {
    data: Array<{ [key: string]: any }>;
}

const DataTable: React.FC<DataTableProps> = ({ data }) => {
    if (data.length === 0) {
        return <p>No data available.</p>;
    }

    // Extract column headers from the first data entry
    const headers = Object.keys(data[0]);

    return (
        <Table striped bordered hover responsive>
            <thead>
            <tr>
                {headers.map((header) => (
                    <th key={header}>{header}</th>
                ))}
            </tr>
            </thead>
            <tbody>
            {data.map((row, index) => (
                <tr key={index}>
                    {headers.map((header) => (
                        <td key={header}>{row[header]}</td>
                    ))}
                </tr>
            ))}
            </tbody>
        </Table>
    );
};

export default DataTable;
