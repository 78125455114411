import React, {useEffect, useState, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';
import {Bar} from 'react-chartjs-2';
import {CopyToClipboard} from "react-copy-to-clipboard";
import axiosInstance from '../../Components/Requests/Axios';
import DataTable from '../../Components/Tables/DataTable';
import './Account.css';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

// Register the necessary Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface AccountInfo {
    username: string;
    email: string;
    api_key: string;
    metadata_route_count: number;
    assets_route_count: number;
    cash_financing_activities_route_count: number;
    cash_investing_activities_route_count: number;
    cash_operating_activities_route_count: number;
    common_stock_route_count: number;
    comprehensive_income_route_count: number;
    cost_of_revenue_route_count: number;
    current_assets_route_count: number;
    current_liabilities_route_count: number;
    depreciation_and_amortization_route_count: number;
    eps_basic_route_count: number;
    eps_diluted_route_count: number;
    goodwill_route_count: number;
    gross_profit_route_count: number;
    historical_data_route_count: number;
    intangible_assets_route_count: number;
    interest_expense_route_count: number;
    inventory_route_count: number;
    liabilities_route_count: number;
    net_income_loss_route_count: number;
    operating_expenses_route_count: number;
    operating_income_route_count: number;
    operating_income_loss_route_count: number;
    preferred_stock_route_count: number;
    property_plant_and_equipment_route_count: number;
    research_and_development_expense_route_count: number;
    retained_earnings_route_count: number;
    revenue_route_count: number;
    shares_route_count: number;
    total_stockholders_equity_route_count: number;
}

const Account: React.FC = () => {
    const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
    const [error, setError] = useState<string>('');
    const hasFetchedData = useRef(false);
    const navigate = useNavigate();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (hasFetchedData.current) return;

        const fetchAccountInfo = async () => {
            hasFetchedData.current = true;
            try {
                const response = await axiosInstance.get<AccountInfo>(`/account`);
                setAccountInfo(response.data);
            } catch (err) {
                setError('Failed to fetch account information.');
            }
        };

        fetchAccountInfo();
    }, [navigate]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!accountInfo) {
        return <div>Loading account information...</div>;
    }

    const usageData = Object.keys(accountInfo)
        .filter(key => key.endsWith('_route_count'))
        .map(key => ({
            "Route": key.replace(/_/g, ' ').replace('route count', '').trim(),
            "Records Consumed": accountInfo[key as keyof AccountInfo]
        }));

    const data = {
        labels: usageData.map(item => item.Route),
        datasets: [
            {
                label: 'API Usage Count',
                data: usageData.map(item => item["Records Consumed"]),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderWidth: 1
            }
        ]
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    };


    const calculateBill = (usageCounts: number[]) => {
        const totalRecords = usageCounts.reduce((acc, count) => acc + count, 0);
        const cost = totalRecords / 1000;
        return cost.toFixed(2);
    };

// Prepare an array of numbers for `currentBill` calculation
    const currentBill = calculateBill(
        Object.entries(accountInfo)
            .filter(([key, value]) => key.endsWith('_route_count') && typeof value === 'number')
            .map(([, value]) => value as number)
    );

    return (
        <Container className="account-container">
            <Row>
                <Col>
                    <Card className="mb-3 card-custom">
                        <Card.Body>
                            <Card.Title className="text-center mb-4">Account Information</Card.Title>
                            <p><strong>Username:</strong> {accountInfo.username}</p>
                            <p><strong>Email:</strong> {accountInfo.email}</p>
                            <p><strong>API Key:</strong></p>
                            <div className="api-key-container" style={{
                                display: 'flex',
                                alignItems: 'center',
                                backgroundColor: '#f8f9fa',
                                padding: '10px',
                                borderRadius: '5px'
                            }}>
                                <pre style={{marginBottom: '0', overflowX: 'auto', backgroundColor: 'transparent'}}>
                                    {accountInfo.api_key}
                                </pre>
                                <CopyToClipboard text={accountInfo.api_key} onCopy={() => setCopied(true)}>
                                    <Button variant="outline-secondary" size="sm" style={{marginLeft: '10px'}}>
                                        {copied ? "Copied!" : "Copy"}
                                    </Button>
                                </CopyToClipboard>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card className="mb-3 card-custom">
                        <Card.Body>
                            <Card.Title>API Usage</Card.Title>
                            <DataTable data={usageData}/>
                            <div className="chart-container mt-4">
                                <Bar className="bar-chart" data={data} options={options}/>
                            </div>
                            <h3 className="mt-3">Current Bill: ${currentBill}</h3>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Account;
