import React from 'react';
import Header from '../../Components/Headers/Header';
import Card from '../../Components/Cards/Card';
import './HomePage.css';

const Home: React.FC = () => {
    return (
        <div className="home-container">
            {/* Intro Section */}
            <Card
                title="Welcome to Equity Explorer"
                content={
                    <>
                        <p>
                            <strong>Equity Explorer</strong> is a personal, hobby project designed to process and
                            provide
                            financial data from quarterly reports submitted by public companies. This project is
                            intended for
                            educational and personal use only and is not meant for commercial purposes.
                        </p>
                        <p>
                            Through this platform, I aim to showcase my skills in full-stack development, data
                            engineering,
                            and machine learning, while providing potential employers with a practical example of my
                            expertise.
                        </p>
                    </>
                }
            />

            {/* Tech Stack Section */}
            <Card
                title="Technologies and Architecture"
                content={
                    <>
                        <p>
                            The core architecture of Equity Explorer is hosted on Digital Ocean:
                        </p>
                        <ul>
                            <li><strong>Frontend:</strong> A single-page application built with React.</li>
                            <li><strong>Backend:</strong> Two FastAPI servers that process data and handle API requests,
                                each sitting behind a load balancer.
                            </li>
                            <li><strong>Database:</strong> PostgreSQL, used to store the processed financial data and
                                user information.
                            </li>
                        </ul>
                    </>
                }
            />

            {/* Pipeline Section */}
            <Card
                title="Data Processing Pipeline"
                content={
                    <>
                        <p>
                            Equity Explorer’s data pipeline pulls quarterly reports in HTML format directly from the
                            <strong> United States Securities and Exchange Commission’s (SEC) Electronic Data Gathering,
                                Analysis, and Retrieval (EDGAR)</strong> system.
                            This data undergoes several processing stages:
                        </p>
                        <ol>
                            <li><strong>Document Conversion:</strong> The HTML documents are converted to PNG image
                                files to make them compatible with computer vision models.
                            </li>
                            <li><strong>Object Detection:</strong> A custom object detection model locates financial
                                tables within each document.
                            </li>
                            <li><strong>Table Structure Detection:</strong> A secondary model identifies and categorizes
                                parts of each table, including data cells, column titles, and units.
                            </li>
                            <li><strong>Optical Character Recognition (OCR):</strong> Using EasyOCR, we extract textual
                                data from the table images.
                            </li>
                            <li><strong>Data Cleaning:</strong> Data undergoes spelling correction and word segmentation
                                before being stored.
                            </li>
                            <li><strong>Database Storage:</strong> The cleaned data is stored in a structured format
                                within PostgreSQL, where it is organized into multiple tables for efficient querying and
                                analysis.
                            </li>
                            <li><strong>Materialized View:</strong> To optimize data retrieval, the database includes a
                                materialized view with indexed columns that refreshes upon ingestion of new data.
                            </li>
                        </ol>
                    </>
                }
            />

            {/* Employer Note Section */}
            <Card
                title="Note to Potential Employers"
                content={
                    <>
                        <p>
                            This project showcases my experience with data engineering, machine learning, and scalable
                            architecture.
                            From building ETL pipelines (Extract, Transform, Load) and managing complex data
                            workflows to working with React, PyTorch, FastAPI, Transformers, and more. If
                            you’re interested in learning more about this project or my professional experience, please feel free to send an email
                            out to elijahanderson96@gmail.com!
                        </p>
                    </>
                }
            />
        </div>
    );
};

export default Home;
