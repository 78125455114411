import React from 'react';
import { Card, Tabs, Tab } from 'react-bootstrap';
import './APIInterface.css';

const IntroductionSection = () => {
    return (
        <div className="api-interface">
            <h1>Financial Data API Interface</h1>
            <Card className="mb-3">
                <Card.Body>
                    <Card.Title>Introduction to APIs</Card.Title>
                    <div>
                        <h3>What is an API?</h3>
                        <p>
                            An <strong>API (Application Programming Interface)</strong> is a set of rules and protocols that allows different software applications to communicate with each other. APIs enable the integration of various software components, facilitating the sharing of data and functionality across applications.
                        </p>
                        <h3>What is a RESTful API?</h3>
                        <p>
                            A <strong>RESTful API</strong> (Representational State Transfer) is an architectural style for designing networked applications. It relies on a stateless, client-server communication protocol—usually HTTP. RESTful APIs use standard HTTP methods such as GET, POST, PUT, and DELETE to perform CRUD (Create, Read, Update, Delete) operations on resources.
                        </p>
                        <h3>Key Concepts of RESTful APIs</h3>
                        <ul>
                            <li><strong>Resources</strong>: Objects or representations of information that are accessible via a unique URI (Uniform Resource Identifier). For example, <code>/financials/assets</code> represents asset data.</li>
                            <li><strong>HTTP Methods</strong>: Actions that can be performed on resources.
                                <ul>
                                    <li><strong>GET</strong>: Retrieve a resource.</li>
                                    <li><strong>POST</strong>: Create a new resource.</li>
                                    <li><strong>PUT</strong>: Update an existing resource.</li>
                                    <li><strong>DELETE</strong>: Remove a resource.</li>
                                </ul>
                            </li>
                            <li><strong>Statelessness</strong>: Each API request from a client contains all the information needed to fulfill the request. The server does not store any client context between requests.</li>
                            <li><strong>JSON</strong>: JavaScript Object Notation is commonly used to format the data exchanged between client and server.</li>
                        </ul>

                        <h3>How to Use Our Financial Data API</h3>
                        <p>
                            Our Financial Data API provides endpoints to fetch a variety of financial data, including assets, income, cash flow activities, and other financial indicators. Below are examples of how to use the API.
                        </p>
                        <p><strong>Base URL</strong>: <code>https://api.equityexplorer.io</code></p>
                        <p><strong>Endpoints</strong>:</p>
                        <ul>
                            <li><code>/financials/assets</code></li>
                            <li><code>/financials/cash_financing_activities</code></li>
                            <li><code>/financials/cash_investing_activities</code></li>
                            <li><code>/financials/cash_operating_activities</code></li>
                            <li><code>/financials/revenue</code></li>
                            <li><code>/financials/net_income_loss</code></li>
                            <li><code>/metadata</code> - Provides information about API usage and resource metadata.</li>
                        </ul>
                        <p>Each endpoint allows you to specify various query parameters to filter the data:</p>
                        <ul>
                            <li><strong>symbol</strong> (optional): Stock symbol (e.g., "AAPL")</li>
                            <li><strong>start_date</strong> (optional): Start date for the data range in YYYY-MM-DD format</li>
                            <li><strong>end_date</strong> (optional): End date for the data range in YYYY-MM-DD format</li>
                            <li><strong>latest_n_records</strong> (optional): Fetch the latest N records</li>
                        </ul>

                        <h3>Example API Requests</h3>
                        <Tabs defaultActiveKey="curl" id="api-examples-tabs">
                            <Tab eventKey="curl" title="cURL">
                                <p><strong>Fetch Asset Data:</strong></p>
                                <pre>{`curl -X GET "https://api.equityexplorer.io/financials/assets?symbol=AAPL&latest_n_records=5" \\ 
-H "X-API-Key: YOUR_API_KEY"`}</pre>
                            </Tab>
                            <Tab eventKey="python" title="Python">
                                <p><strong>Fetch Asset Data:</strong></p>
                                <pre>{`import requests

url = 'https://api.equityexplorer.io/financials/assets'
headers = {'X-API-Key': 'YOUR_API_KEY'}
params = {'symbol': 'AAPL', 'latest_n_records': 5}

response = requests.get(url, headers=headers, params=params)
print(response.json())`}</pre>
                            </Tab>
                            <Tab eventKey="javascript" title="JavaScript">
                                <p><strong>Fetch Asset Data:</strong></p>
                                <pre>{`const axios = require('axios');

const url = 'https://api.equityexplorer.io/financials/assets';
const headers = {
    'X-API-Key': 'YOUR_API_KEY'
};
const params = {
    symbol: 'AAPL',
    latest_n_records: 5
};

axios.get(url, { headers, params })
    .then(response => {
        console.log(response.data);
    })
    .catch(error => {
        console.error(error);
    });`}</pre>
                            </Tab>
                        </Tabs>

                        <h3>Authentication</h3>
                        <p>
                            Our API requires authentication to ensure that only authorized users can access the data. You can authenticate by including your API key in the headers of your requests.
                        </p>
                        <p><strong>Why Use Headers for API Keys?</strong></p>
                        <p>
                            Placing the API key in the headers is a secure method that avoids exposing sensitive data in URLs, which can be logged or cached in unintended places like server logs or browser history.
                        </p>

                        <h3>Tips for Using the API</h3>
                        <ul>
                            <li><strong>Authentication</strong>: Always include your API key in the headers to access data securely.</li>
                            <li><strong>Error Handling</strong>: Check for HTTP status codes and handle errors gracefully. Implement retry logic if necessary.</li>
                            <li><strong>Rate Limiting</strong>: Adhere to rate limits to prevent throttling or access restrictions. This information is available in the API documentation.</li>
                            <li><strong>Optimize Requests</strong>: Use filters like <code>symbol</code>, <code>start_date</code>, and <code>end_date</code> to retrieve only the data you need, minimizing bandwidth and load time.</li>
                        </ul>

                        <h3>Resources for Further Learning</h3>
                        <ul>
                            <li><a href="https://restfulapi.net/" target="_blank" rel="noopener noreferrer">REST API Tutorial</a></li>
                            <li><a href="https://docs.github.com/en/rest" target="_blank" rel="noopener noreferrer">Introduction to REST APIs on GitHub</a></li>
                            <li><a href="https://www.postman.com/api-documentation-tool/" target="_blank" rel="noopener noreferrer">API Documentation Tool - Postman</a></li>
                        </ul>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};

export default IntroductionSection;
