import React, { useState } from 'react';
import Card from '../../Components/Cards/Card';
import SymbolSearchBar from "../../Components/SearchBar/SearchBar";
import FinancialTabs from '../../Components/Tabs/StockTabs'; // Import FinancialTabs component
import './Stocks.css';

const Stocks: React.FC = () => {
    const [selectedSymbols, setSelectedSymbols] = useState<string[]>([]);

    const handleSymbolSelect = (symbols: string[]) => {
        setSelectedSymbols(symbols);
        console.log(`Selected symbols: ${symbols}`);
    };

    return (
        <div className="home-container">
            <Card
                title="Stocks"
                content={
                    <div className="search-bar-container">
                        <SymbolSearchBar onSelect={handleSymbolSelect} />
                    </div>
                }
            />

            {selectedSymbols.length > 0 && (
                <div className="selected-symbols">
                    <h3>Financial Data for: {selectedSymbols.join(', ')}</h3>
                    <h4>This feature is temporarily down.</h4>
                    {/*<FinancialTabs symbols={selectedSymbols} />*/}
                </div>
            )}
        </div>
    );
};

export default Stocks;
